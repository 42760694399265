export enum Icons8 {
  Link = "link--v1",
  Facebook = "facebook--v1",
  Twitter = "twitter-squared",
  Instagram = "instagram-new--v1",
  LinkedIn = "linkedin",
  GitHub = "github",
  Website = "internet-browser--v1",
  Appointment = "calendar--v1",
}

export interface IconOption {
  id: number;
  label: string;
  value: string;
}

export const getIconsOptions = (): IconOption[] => {
  return Object.keys(Icons8).map((v, i) => ({
    id: i,
    label: v,
    value: Icons8[v as keyof typeof Icons8],
  }));
};
