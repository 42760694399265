import React, { useState, MouseEvent, useEffect } from "react";
import { BlockPicker } from "react-color";
import EmailOrganizationTemplate from "./EmailOrganizationTemplate";
import GenerateURLButton from "./GenerateURLButton";
import { SignatureLink } from "../lib/organizations/types/signatureLink";
import SignatureLinkRow from "./SignatureLinkRow";
import { IconOption, getIconsOptions } from "../services/Icons8";

interface Props {
  organizationColor: string;
  setOrganizationColor: React.Dispatch<React.SetStateAction<string>>;
}

const OrganizationTemplateForm: React.FC<Props> = ({
  organizationColor,
  setOrganizationColor,
}) => {
  const [organizationName, setOrganizationName] = useState("");
  const [slogan, setSlogan] = useState<string>();
  const [logoUrl, setLogoUrl] = useState("");
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [signatureLinks, setSignatureLinks] = useState<SignatureLink[]>([]);
  // TODO: Create a state to store the available icons options.
  // update this state removing an already selected social media icon.
  //useMemo to calculate options
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    ["Link"] || []
  );

  useEffect(() => {
    setSelectedOptions((previousState) => {
      return [...previousState, "Link"];
    });
  }, [selectedOptions]);
  const handleColorChange = (color: any) => {
    setOrganizationColor(color.hex);
  };

  const onUrlAdded = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setSignatureLinks((previousState) => {
      return [
        ...previousState,
        { title: "", iconLabel: "", url: "", isEditable: false },
      ];
    });
  };

  return (
    <>
      <div className=" mx-10 my-4 w-3/4 md:w-2/5 md:basis-2/4 p-5  bg-white rounded">
        <div className="text-center mb-10 py-3">
          <span
            style={{
              fontSize: "2.0rem",
              fontWeight: "600",
            }}
          >
            Enter your info.
          </span>
        </div>
        <form action="" className="space-y-6 pl-2 mb-10 md:pl-5 lg:pl-10">
          <div className="flex flex-col space-y-2">
            <label className="">Enter your organization name</label>
            <input
              className="border-b-2 border-black  outline-none w-3/4 "
              type="text"
              onChange={(event) => setOrganizationName(event.target.value)}
            />
          </div>
          <div className="flex flex-col space-y-2">
            <label>Slogan</label>
            <input
              type="text"
              className="border-b-2 border-black  outline-none  w-3/4"
              onChange={(event) => setSlogan(event.target.value)}
            />
          </div>
          <div className="flex flex-col space-y-2">
            <label>Logo</label>
            <input
              type="text"
              className="border-b-2 border-black  outline-none  w-3/4"
              onChange={(event) => setLogoUrl(event.target.value)}
            />
          </div>

          <div className="flex  space-y-2 md:basis-full">
            <div className="flex flex-col gap-4 ">
              <label>Color</label>
              <input
                type="text"
                className="border-b-2 border-black outline-none  w-3/4"
                onChange={(event) => setOrganizationColor(event.target.value)}
                value={organizationColor}
              />
              <div>
                <BlockPicker
                  className="border-2 outline-slate-400"
                  color={organizationColor}
                  onChange={handleColorChange}
                  onChangeComplete={() => {}}
                  triangle="hide"
                  width="200px"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col space-y-2">
            <label>Phone</label>
            <input
              type="tel"
              className="border-b-2 border-black outline-none  w-3/4"
              onChange={(event) => setPhoneNumber(event.target.value)}
            />
          </div>
          {signatureLinks?.map((v, i) => {
            return (
              <SignatureLinkRow
                key={i}
                organizationColor={organizationColor}
                signatureLink={v}
                setSignatureLinks={setSignatureLinks}
                availableOptions={selectedOptions}
                setAvailableOptions={setSelectedOptions}
                index={i}
              />
            );
          })}
          <button
            onClick={onUrlAdded}
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
          >
            Add url
          </button>
        </form>
      </div>
      <div className="text-center md:flex-col md:m-5 w-full md:basis-2/4 mx-10 pr-10  bg-white rounded md:h-96 md:w-full">
        <div className="text-center md:flex-row py-3">
          <span
            style={{
              fontSize: "2.0rem",
              fontWeight: "600",
            }}
          >
            Your email signature.
          </span>
        </div>
        <div className="rounded-md  w-[22rem] h-40 md:w-full  md:py-2 md:ml-52 md:flex-row">
          <EmailOrganizationTemplate
            organizationColor={organizationColor}
            organizationName={organizationName}
            slogan={slogan}
            logoUrl={logoUrl}
            phoneNumber={phoneNumber}
            signatureLinks={signatureLinks}
          />
        </div>

        <div className="  my-10 md:mt-16 md:flex-row md">
          <GenerateURLButton
            organizationColor={organizationColor}
            organizationName={organizationName}
            slogan={slogan}
            logoUrl={logoUrl}
            phoneNumber={phoneNumber}
            signatureLinks={signatureLinks}
            availableOptions={getAvailableOptions(selectedOptions)}
          />
        </div>
      </div>
    </>
  );
};

export function getAvailableOptions(selectedOptions: string[]): string[] {
  const differenceFilter = (e: IconOption) => selectedOptions.includes(e.label);
  const mapToString = (e: IconOption) => e.label;
  const result = getIconsOptions().filter(differenceFilter).map(mapToString);

  return result;
}

export default OrganizationTemplateForm;
