export function sanitizeWebsite(website: string) {
  let result: string;

  try {
    result = new URL(website).toString();
  } catch (error) {
    result = `https://${website}`;
    return result;
  }
  return result;
}
