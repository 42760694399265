import { HashRouter, Route, Routes } from "react-router-dom";
import EmployeeView from "./routes/employee/view";
import Home from "./routes/Home";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/employee/view" element={<EmployeeView />} />
      </Routes>
    </HashRouter>
  );
}

export default App;
