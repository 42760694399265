import React from "react";

const NavbarSignature: React.FC<{ color: string; logoURL?: string }> = ({
  color,
  logoURL,
}) => {
  const style = {
    backgroundColor: `${color}`,
  };

  return (
    <header>
      <nav className="" style={style}>
        <div className="container flex items-center justify-between ">
          <div>
            <img
              src={logoURL}
              alt=""
              className="d-line-block align-text-top object-contain h-20 ml-5 "
            />
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavbarSignature;
