import { Dispatch, FormEvent, Fragment, SetStateAction, useState } from "react";
import { SignatureLink } from "../lib/organizations/types/signatureLink";
import { Listbox, Transition } from "@headlessui/react";
import { getIconsOptions } from "../services/Icons8";
import Icon from "./Icon";

type Props = {
  signatureLink: SignatureLink;
  setSignatureLinks: Dispatch<SetStateAction<SignatureLink[]>>;
  availableOptions: string[];
  setAvailableOptions: Dispatch<SetStateAction<string[]>>;
  index: number;
  organizationColor: string;
  employeeEditableRow?: boolean;
  employeeAddRow?: boolean;
};

const SignatureLinkRow = (props: Props) => {
  const iconsOptions = getIconsOptions();
  const nonMatchingValues = [
    { id: 0, label: "Link", value: "link--v1" },
    ...iconsOptions.filter(
      (value) => !props.availableOptions.includes(value.label)
    ),
  ];

  const [iconLabel, setIconLabel] = useState<string>(
    props.signatureLink.iconLabel || nonMatchingValues[0].label
  );
  const [url, setUrl] = useState<string>(props.signatureLink.url);
  const [isEditable, setIsEditable] = useState<boolean>(
    props.signatureLink.isEditable
  );

  const onChangeDelete = () => {
    props.setSignatureLinks((previousState) => {
      const ps = [...previousState];
      ps.splice(props.index, 1);
      return [...ps];
    });

    props.setAvailableOptions((previousState) => {
      const ps = [...previousState];
      ps.splice(props.index, 1);
      return [...ps];
    });
  };

  const onChangeImage = (event: string) => {
    const newIconLabel = event;
    setIconLabel(() => {
      props.setSignatureLinks((prevState) => {
        const ps = [...prevState];
        ps.splice(props.index, 1, {
          iconLabel: newIconLabel,
          url,
          isEditable,
        });
        return [...ps];
      });
      props.setAvailableOptions((previousState) => {
        const ps = [...previousState];
        ps.splice(props.index, 1, newIconLabel);
        return [...ps];
      });
      return newIconLabel;
    });
  };

  const onChangeUrl = (event: FormEvent<HTMLInputElement>) => {
    const newUrl = event.currentTarget.value;
    setUrl(() => {
      props.setSignatureLinks((previousState) => {
        const ps = [...previousState];
        ps.splice(props.index, 1, {
          iconLabel,
          isEditable,
          url: newUrl,
        });
        return [...ps];
      });
      return newUrl;
    });
  };

  const onChangeIsEditable = (event: FormEvent<HTMLInputElement>) => {
    setIsEditable((prevIsEditable) => {
      props.setSignatureLinks((previousState) => {
        const ps = [...previousState];
        ps.splice(props.index, 1, {
          iconLabel,
          isEditable: !prevIsEditable,
          url,
        });
        return [...ps];
      });
      return !prevIsEditable;
    });
  };

  return (
    <div className="border-2 border-neutral-300 bg-neutral-100 space-y-2 rounded-md w-3/4 p-5">
      {props.employeeEditableRow ? (
        <>
          <div className="flex flex-col">
            <label>Select an Icon</label>
            <Listbox value={iconLabel} onChange={onChangeImage} disabled>
              <Listbox.Button
                className={
                  "w-3/4 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-center shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                }
              >
                {iconLabel}
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={
                    "mt-1 max-h-60 w-3/4 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
                  }
                >
                  {nonMatchingValues.map((v) => {
                    return (
                      <Listbox.Option
                        key={v.id}
                        value={v.label}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 md:pl-10 pr-4 ${
                            active
                              ? "bg-amber-100 text-amber-900"
                              : "text-gray-900"
                          }`
                        }
                      >
                        <div className="flex items-center justify-start ">
                          <Icon
                            color={props.organizationColor.replace("#", "")}
                            name={v.value}
                            size="30"
                          />
                          <span className="md:ml-5 ml-2">{v.label}</span>
                        </div>
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </Listbox>
          </div>

          <div className="flex flex-col">
            <label>URL</label>
            <input
              type="text"
              className="border-b-2 border-black w-3/4 outline-none bg-neutral-100"
              value={url}
              onChange={onChangeUrl}
            />
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-row justify-end ">
            <button
              className="flex justify-end p-0.5 rounded-sm border-2 h-7 w-5 content-center font-bold text-xs  border-neutral-300 bg-neutral-100"
              onClick={onChangeDelete}
            >
              <span className="sr-only">Close menu</span>

              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="flex flex-col">
            <label>Select an Icon</label>
            <Listbox value={iconLabel} onChange={onChangeImage}>
              <Listbox.Button
                className={
                  "w-3/4 cursor-default rounded-lg bg-white py-2 pl-3 pr-10 text-center shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm"
                }
              >
                {iconLabel}
              </Listbox.Button>
              <Transition
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  className={
                    "mt-1 max-h-60 w-3/4 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
                  }
                >
                  {nonMatchingValues.map((v) => {
                    return (
                      <Listbox.Option
                        key={v.id}
                        value={v.label}
                        className={({ active }) =>
                          `relative cursor-default select-none py-2 md:pl-10 pr-4 ${
                            active
                              ? "bg-amber-100 text-amber-900"
                              : "text-gray-900"
                          }`
                        }
                      >
                        <div className="flex items-center justify-start ">
                          <Icon
                            color={props.organizationColor.replace("#", "")}
                            name={v.value}
                            size="30"
                          />
                          <span className="md:ml-5 ml-2">{v.label}</span>
                        </div>
                      </Listbox.Option>
                    );
                  })}
                </Listbox.Options>
              </Transition>
            </Listbox>
          </div>

          <div className="flex flex-col">
            <label>URL</label>
            <input
              type="text"
              className="border-b-2 border-black w-3/4 outline-none bg-neutral-100"
              value={url}
              onChange={onChangeUrl}
            />
          </div>
          {props.employeeAddRow ? (
            <></>
          ) : (
            <div className="flex items-center">
              <label>Is editable</label>
              <input
                type="checkbox"
                className="w-4 h-4 ml-2 bg-neutral-200"
                checked={isEditable}
                onChange={onChangeIsEditable}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default SignatureLinkRow;
