import React, { useEffect, useState } from "react";
import NavbarSignature from "../../components/NavbarSignature";
import EmailEmployeeForm from "../../components/EmployeeTemplateForm";
import { useSearchParams } from "react-router-dom";
import { SignatureLink } from "../../lib/organizations/types/signatureLink";

type Props = {
  organizationColor: string;
  organizationName: string;
  slogan?: string;
  logoUrl: string;
  phoneNumber?: string;
  signatureLinks: SignatureLink[];
  availableOptions?: string[];
};

const EmployeeView: React.FC = () => {
  const [data, setData] = useState<Props | null>(null);

  const [searchP] = useSearchParams();
  const employeeName = searchP.get("employeeName");
  const employeeRole = searchP.get("employeeRole");
  const employeePhone = searchP.get("employeePhone");
  const employeeEmail = searchP.get("employeeEmail");

  useEffect(() => {
    const hash = window.location.hash;
    const infoIndex = hash.indexOf("info=");
    if (infoIndex !== -1) {
      const encodedData = hash.slice(infoIndex + 5);
      var decodedData = atob(encodedData);
      try {
        const parsedData = JSON.parse(decodedData);
        setData(parsedData);
      } catch (error) {
        console.error("Error parsing data:", error);
        setData(null);
      }
    }
  }, []);

  if (!data) {
    return <div>No data</div>;
  }

  return (
    <>
      <NavbarSignature color={data.organizationColor} logoURL={data.logoUrl} />
      <EmailEmployeeForm
        employeeName={employeeName as string}
        employeeRole={employeeRole as string}
        employeePhone={employeePhone as string}
        employeeEmail={employeeEmail as string}
        organizationColor={data.organizationColor}
        organizationName={data.organizationName}
        slogan={data.slogan}
        logoUrl={data.logoUrl}
        phoneNumber={data.phoneNumber}
        signatureLinks={data.signatureLinks}
        availableOptions={data.availableOptions || []}
      />
    </>
  );
};

export default EmployeeView;
