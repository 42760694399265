import React from "react";

export interface IconProps {
  name: string;
  size: string;
  color: string;
}

const Icon: React.FC<IconProps> = ({
  name = "link--v1",
  size = "50",
  color = "000000",
}) => {
  return (
    <img
      width={size}
      height={size}
      src={`https://img.icons8.com/${color}/ios-filled/${size}/${name}.png`}
      // onError={({ currentTarget }) => {
      //   currentTarget.onerror = null; // prevents looping
      //   currentTarget.src =
      //     "https://picturesofmaidenhead.files.wordpress.com/2019/01/image-not-found.jpg?w=1620";
      // }}
      alt={name}
    />
  );
};

export default Icon;
