import React, { useState, MouseEvent, useEffect } from "react";
import EmailSignatureBuilder from "./EmailSignatureBuilder";
import { SignatureLink } from "../lib/organizations/types/signatureLink";
import SignatureLinkRow from "./SignatureLinkRow";
import { getAvailableOptions } from "./OrganizationTemplateForm";

interface Props {
  organizationColor: string;
  organizationName: string;
  slogan?: string;
  logoUrl: string;
  phoneNumber?: string;
  signatureLinks: SignatureLink[];
  availableOptions: string[];
  employeeName: string;
  employeeRole: string;
  employeeEmail: string;
  employeePhone: string;
}

const EmailTemplateForm: React.FC<Props> = ({
  organizationColor,
  organizationName,
  slogan,
  logoUrl,
  phoneNumber,
  employeeName,
  employeeRole,
  employeePhone,
  employeeEmail,
  signatureLinks,
  availableOptions,
}) => {
  const style = {
    width: "200px",
    backgroundColor: `${organizationColor}`,
    color: "white",
    padding: "15px",
    borderRadius: "5px",
    margin: "20px 0 10px 0",
  };

  const [name, setName] = useState(employeeName);
  const [role, setRole] = useState(employeeRole);
  const [phone, setPhone] = useState(employeePhone);
  const [email, setEmail] = useState(employeeEmail);
  const [employeeEditableRow] = useState<boolean>(true);
  const [employeeAddRow] = useState<boolean>(true);
  const [updatedOptions, setUpdatedOptions] = useState<string[]>(
    ["Link", ...availableOptions] || []
  );
  const [selectedSignatureLinks, setSelectedSignatureLinks] =
    useState<SignatureLink[]>(signatureLinks);

  const [employeeSignatureLinks, setEmployeeSignatureLinks] = useState<
    SignatureLink[]
  >([]);

  useEffect(() => {
    setUpdatedOptions((previousState) => {
      return [...previousState, ...availableOptions];
    });
  }, [updatedOptions, availableOptions]);

  const onUrlAdded = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setEmployeeSignatureLinks((previousState) => {
      return [
        ...previousState,
        { title: "", iconLabel: "", url: "", isEditable: true },
      ];
    });
  };

  const copyToClipboard = (elementToCopy: HTMLElement | null) => {
    if (navigator.clipboard?.write) {
      const mediaType = "text/html";
      const blob = new Blob([elementToCopy?.outerHTML as string], {
        type: mediaType,
      });
      const data = [new ClipboardItem({ [mediaType]: blob })];

      navigator.clipboard.write(data).then(
        () => alert("copy success"),
        () => alert("error on copy")
      );
    } else {
      let range: Range;
      let selection: Selection | any;

      if (document.createRange && window.getSelection) {
        range = document.createRange();
        selection = window.getSelection();
        selection.removeAllRanges();
        try {
          range.selectNodeContents(elementToCopy as Node);
          selection.addRange(range);
        } catch (e) {
          range.selectNode(elementToCopy as Node);
          selection.addRange(range);
        }

        document.execCommand("copy");
      }
    }
  };

  return (
    <>
      <div className="flex justify-center md:justify-between   md:w-full md:px-20 overflow-x-auto lg:overflow-x-hidden ">
        <div className=" flex flex-col md:flex-row md:w-full">
          <div className="  mx-10 my-4 w-3/4 md:w-2/5 md:basis-2/4 p-3  bg-white rounded">
            <div className="text-center mb-10 py-3">
              <span
                style={{
                  fontSize: "2.0rem",
                  fontWeight: "600",
                }}
              >
                Enter your info.
              </span>
            </div>
            <form action="" className="space-y-6 pl-2 mb-10 md:pl-5 lg:pl-20">
              <div className="flex flex-col space-y-2">
                <label className="">Enter your name</label>
                <input
                  className="border-b-2 border-black w-3/4 outline-none  "
                  type="text"
                  onChange={(event) => setName(event.target.value)}
                  value={name ? name : ""}
                  readOnly={employeeName ? true : false}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label>Role</label>
                <input
                  type="text"
                  className="border-b-2 border-black  w-3/4 outline-none"
                  onChange={(event) => setRole(event.target.value)}
                  value={role ? role : ""}
                  readOnly={employeeRole ? true : false}
                />
              </div>
              <div className="flex flex-col space-y-2">
                <label>Phone</label>
                <input
                  type="tel"
                  className="border-b-2 border-black  w-3/4 outline-none"
                  onChange={(event) => setPhone(event.target.value)}
                  value={phone ? phone : ""}
                  readOnly={employeePhone ? true : false}
                />
              </div>

              <div className="flex flex-col space-y-2">
                <label>Email</label>
                <input
                  type="text"
                  className="border-b-2 border-black  w-3/4 outline-none"
                  onChange={(event) => setEmail(event.target.value)}
                  value={email ? email : ""}
                  readOnly={employeeEmail ? true : false}
                />
              </div>

              {selectedSignatureLinks?.map((v, i) => {
                return v.isEditable ? (
                  <SignatureLinkRow
                    key={i}
                    organizationColor={organizationColor}
                    signatureLink={v}
                    setSignatureLinks={setSelectedSignatureLinks}
                    availableOptions={getAvailableOptions(updatedOptions)}
                    setAvailableOptions={setUpdatedOptions}
                    index={i}
                    employeeEditableRow={employeeEditableRow}
                  />
                ) : (
                  <></>
                );
              })}

              {employeeSignatureLinks?.map((v, i) => {
                return (
                  <SignatureLinkRow
                    key={i}
                    organizationColor={organizationColor}
                    signatureLink={v}
                    setSignatureLinks={setEmployeeSignatureLinks}
                    availableOptions={getAvailableOptions(updatedOptions)}
                    setAvailableOptions={setUpdatedOptions}
                    index={i}
                    employeeAddRow={employeeAddRow}
                  />
                );
              })}

              <button
                onClick={onUrlAdded}
                className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
              >
                Add url
              </button>
            </form>
          </div>
          <div className="text-center md:flex-col md:m-5 w-full md:basis-2/4 mx-10 pr-10  bg-white rounded md:h-96 md:w-full">
            <div className="text-center md:flex-row py-3">
              <span
                style={{
                  fontSize: "2.0rem",
                  fontWeight: "600",
                }}
              >
                Your email signature.
              </span>
            </div>

            <div className=" rounded-md  w-80 h-52 md:w-full  md:py-2 md:ml-40 md:flex-row">
              <EmailSignatureBuilder
                organizationColor={organizationColor}
                organizationName={organizationName}
                slogan={slogan}
                logoUrl={logoUrl}
                phoneNumber={phoneNumber}
                employeeName={name}
                employeeEmail={email}
                employeeRole={role}
                employeePhone={phone}
                signatureLinks={selectedSignatureLinks}
                employeeSignatureLinks={employeeSignatureLinks}
              />
            </div>
            <div className="  rounded-lg text-center mt-0 my-10  md:flex-row ">
              <button
                id="copyButton"
                type="button"
                style={style}
                onClick={() =>
                  copyToClipboard(
                    document.getElementById("tableId") as HTMLElement
                  )
                }
              >
                Copy to clipboard
                <span role="img" aria-label="copy emoji">
                  📝
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailTemplateForm;
