import { BaseSignature } from "../lib/organizations/types/baseSignature";
import { sanitizeWebsite } from "../utils/template";
import React from "react";
import Icon from "./Icon";
import { Icons8 } from "../services/Icons8";

const EmailOrganizationTemplate: React.FC<BaseSignature> = ({
  organizationColor,
  organizationName,
  slogan,
  logoUrl,
  phoneNumber,
  signatureLinks,
}) => {
  const iconsOptions = Object.keys(Icons8).map((v, i) => ({
    id: i,
    label: v,
    value: Icons8[v as keyof typeof Icons8],
  }));
  return (
    <>
      <table
        style={{
          display: "table",
          borderCollapse: "separate",
          boxSizing: "border-box",
          textIndent: "initial",
          borderSpacing: "0 0px",
          borderColor: "grey",
          fontFamily: "sans-serif",
          paddingTop: "5px",
          minWidth: "420px",
        }}
        id="tableId"
        border={0}
        cellPadding="0"
        cellSpacing="0"
      >
        <tbody>
          <tr>
            <td
              width="180px"
              height="150px"
              style={{
                padding: "15px",
                textAlign: "center",
              }}
            >
              <table>
                <tbody>
                  <tr>
                    <td>
                      <img
                        height={"100%"}
                        src={logoUrl}
                        alt=""
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src =
                            "https://miro.medium.com/v2/resize:fit:720/format:webp/1*hFwwQAW45673VGKrMPE2qQ.png";
                        }}
                        style={{
                          maxWidth: "180px",
                          maxHeight: "150px",
                        }}
                      ></img>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span
                        style={{
                          textAlign: "center",
                          fontSize: "9px",
                        }}
                      >
                        {slogan}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
            <td
              min-width="400px"
              align="left"
              style={{
                paddingLeft: "15px",
                borderLeft: `3.5px solid  ${organizationColor}`,
                borderRight: "inherit",
                borderBottom: "inherit",
                borderTop: "inherit",
                textAlign: "left",
              }}
            >
              <table
                border={0}
                align="left"
                cellPadding="0"
                cellSpacing="0"
                width="300px"
                style={{ textAlign: "left" }}
              >
                <tbody>
                  <tr>
                    <td
                      style={{
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      Jennifer Williams
                    </td>
                  </tr>
                  <tr>
                    <td
                      style={{
                        fontSize: "12px",
                        fontWeight: "500",
                      }}
                    >
                      GRAPHIC DESIGNER
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ paddingRight: "2px" }}>
                              <a href={"tel:" + phoneNumber}>
                                <span
                                  style={{
                                    marginRight: "2px",
                                    fontSize: "12px",
                                    fontWeight: "900",
                                  }}
                                >
                                  c:
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  {phoneNumber}
                                </span>
                              </a>
                            </td>
                            <td style={{ fontWeight: "600", fontSize: "12px" }}>
                              <span>|</span>
                            </td>
                            <td
                              style={{
                                paddingLeft: "2px",
                              }}
                            >
                              <a href={"tel:" + phoneNumber}>
                                <span
                                  style={{
                                    marginRight: "2px",
                                    fontSize: "12px",
                                    fontWeight: "900",
                                  }}
                                >
                                  m:
                                </span>
                                <span style={{ fontSize: "12px" }}>
                                  (212) 9858547
                                </span>
                              </a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ paddingRight: "2px" }}>
                              <span
                                style={{
                                  marginRight: "2px",
                                  fontSize: "12px",
                                  fontWeight: "900",
                                }}
                              >
                                e:
                              </span>
                              <span style={{ fontSize: "12px" }}>
                                example@email.com
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td style={{ paddingTop: "10px" }}>
                              <table>
                                <tbody>
                                  <tr>
                                    {signatureLinks?.map((v, index) =>
                                      v.url.length > 0 ? (
                                        <td key={index}>
                                          <a
                                            href={sanitizeWebsite(v.url)}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            <Icon
                                              name={
                                                iconsOptions.filter(
                                                  (e) => e.label === v.iconLabel
                                                )[0].value
                                              }
                                              color={organizationColor.replace(
                                                "#",
                                                ""
                                              )}
                                              size="25"
                                            />
                                          </a>
                                        </td>
                                      ) : null
                                    )}
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default EmailOrganizationTemplate;
