import React, { useState } from "react";
import OrganizationTemplateForm from "../components/OrganizationTemplateForm";
import NavbarSignature from "../components/NavbarSignature";

const Home: React.FC<{}> = () => {
  const [organizationColor, setOrganizationColor] = useState<string>("#000000");
  return (
    <>
      <NavbarSignature color={organizationColor} />
      <div className="flex justify-center md:justify-between   md:w-full md:px-20 overflow-x-auto lg:overflow-x-hidden ">
        <div className="flex flex-col md:flex-row  md:w-full ">
          <OrganizationTemplateForm
            organizationColor={organizationColor}
            setOrganizationColor={setOrganizationColor}
          />
        </div>
      </div>
    </>
  );
};

export default Home;
