import React from "react";
import { SignatureLink } from "../lib/organizations/types/signatureLink";

type Props = {
  organizationColor: string;
  organizationName: string;
  slogan?: string;
  logoUrl: string;
  phoneNumber?: string;
  signatureLinks: SignatureLink[];
  availableOptions?: string[];
};

const GenerateURLButton: React.FC<Props> = ({
  organizationColor,
  organizationName,
  slogan,
  logoUrl,
  phoneNumber,
  signatureLinks,
  availableOptions,
}) => {
  const generateURL = () => {
    const data = {
      organizationName: organizationName,
      organizationColor: organizationColor,
      slogan: slogan || "",
      logoUrl: logoUrl || "",
      phoneNumber: phoneNumber || "",
      signatureLinks: signatureLinks || "",
      availableOptions: availableOptions || "",
    };

    const dataStringify = JSON.stringify(data);
    var encodedData = btoa(dataStringify);
    const signatureURL = `${window.location.origin}/#/employee/view?info=${encodedData}`;
    return signatureURL;
  };

  const handleCopyToClipboard = () => {
    const url = generateURL();
    navigator.clipboard.writeText(url).then(
      () => alert("Template URL copied to clipboard!"),
      () => alert("Failed to copy signature URL to clipboard.")
    );
  };
  return (
    <>
      <button
        onClick={handleCopyToClipboard}
        style={{
          width: "200px",
          backgroundColor: `${organizationColor}`,
          color: "white",
          padding: "15px",
          borderRadius: "5px",
          margin: "20px 0 10px 0",
        }}
      >
        Create Template
      </button>
    </>
  );
};
export default GenerateURLButton;
